import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRequired from "../utils/AuthRequired";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'BaikKaryawan',
    beforeEnter: AuthRequired,
    component: () => import(/* webpackChunkName: "home" */ '../App.vue'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../pages/Home.vue')
      },
      {
        path: 'presensi',
        name: 'Presensi',
        component: () => import(/* webpackChunkName: "presensi" */ '../pages/Presensi.vue')
      },
      {
        path: 'absensi',
        name: 'Absensi',
        component: () => import(/* webpackChunkName: "absensi" */ '../pages/Absensi.vue')
      },
      {
        path: 'absensi/form',
        name: 'Absensi Form',
        component: () => import(/* webpackChunkName: "absensi-form" */ '../pages/AbsensiForm.vue')
      },
      {
        path: 'keterlambatan/form',
        name: 'Keterlambatan Form',
        component: () => import(/* webpackChunkName: "absensi-form" */ '../pages/KeterlambatanForm.vue')
      },
      {
        path: 'info',
        name: 'Info',
        component: () => import(/* webpackChunkName: "info" */ '../pages/Info.vue')
      },
      {
        path: 'info/:id',
        name: 'Info Detail',
        component: () => import(/* webpackChunkName: "info-detail" */ '../pages/InfoDetail.vue')
      },
      {
        path: 'saldo',
        name: 'Saldo',
        component: () => import(/* webpackChunkName: "saldo" */ '../pages/Saldo.vue')
      },
      {
        path: 'kasbon',
        name: 'Kasbon',
        component: () => import(/* webpackChunkName: "kasbon" */ '../pages/Kasbon.vue')
      },
      {
        path: 'kasbon/form',
        name: 'Kasbon Form',
        component: () => import(/* webpackChunkName: "kasbon-form" */ '../pages/KasbonForm.vue')
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../pages/Profile.vue')
      },
      {
        path: 'profile/update',
        name: 'Profile Update',
        component: () => import(/* webpackChunkName: "profile-update" */ '../pages/ProfileForm.vue')
      },
      {
        path: 'profile/update-password',
        name: 'Update Password',
        component: () => import(/* webpackChunkName: "update-password" */ '../pages/UbahPassword.vue')
      },
      {
        path: 'faq',
        name: 'Faq',
        component: () => import(/* webpackChunkName: "faq" */ '../pages/Faq.vue')
      },
      {
        path: 'regis-lokasi',
        name: 'Regis Lokasi Kantor',
        component: () => import(/* webpackChunkName: "regis-lokasi" */ '../pages/RegisLokasi.vue')
      },
      {
        path: 'absen-security',
        name: 'Absen Security',
        component: () => import(/* webpackChunkName: "regis-lokasi" */ '../pages/AbsenSecurity.vue')
      },
      {
        path: 'form-absen-security',
        name: 'Form Absen Security',
        component: () => import(/* webpackChunkName: "regis-lokasi" */ '../pages/AbsenSecurityForm.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
