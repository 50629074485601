<template>
  <div class="baik-title w-100">
    <div class="b-navigation bg-light d-flex justify-content-between py-2">
      <div
        class="bn-item home"
        :class="$route.name == 'Home' ? 'active' : null"
      >
        <router-link
          to="/home"
          class="d-flex home flex-column justify-content-center align-items-center"
        >
          <b-icon icon="grid-fill" />
          <span>Home</span>
        </router-link>
      </div>
      <div
        class="bn-item presensi"
        :class="$route.name == 'Presensi' ? 'active' : null"
      >
        <router-link
          to="/presensi"
          class="d-flex presensi flex-column justify-content-center align-items-center"
        >
          <b-icon icon="check-circle-fill" />
          <span>Presensi</span>
        </router-link>
      </div>
      <div
        class="bn-item absensi"
        :class="
          $route.name == 'Absensi' || $route.name == 'Absensi Form'
            ? 'active'
            : null
        "
      >
        <router-link
          to="/absensi"
          class="d-flex absensi flex-column justify-content-center align-items-center"
        >
          <b-icon icon="calendar2-week-fill" />
          <span>Absensi</span>
        </router-link>
      </div>
      <div
        class="bn-item info"
        :class="
          $route.name == 'Info' || $route.name == 'Info Detail'
            ? 'active'
            : null
        "
      >
        <router-link
          to="/info"
          class="d-flex info flex-column justify-content-center align-items-center"
        >
          <b-icon icon="info-circle-fill" />
          <span>Info</span>
        </router-link>
      </div>
      <div
        class="bn-item saldo"
        :class="$route.name == 'Saldo' ? 'active' : null"
      >
        <router-link
          to="/saldo"
          class="d-flex saldo flex-column justify-content-center align-items-center"
        >
          <b-icon icon="briefcase-fill" />
          <span>Saldo</span>
        </router-link>
      </div>
      <div
        class="bn-item kasbon"
        :class="$route.name == 'Kasbon' ? 'active' : null"
      >
        <router-link
          to="/kasbon"
          class="d-flex kasbon flex-column justify-content-center align-items-center"
        >
          <b-icon icon="wallet-fill" />
          <span>Kasbon</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<style>
.b-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding: 10px;
}

.bn-item {
  padding: 0 10px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Tambahkan properti justify-content */
  text-align: center; /* Tambahkan properti text-align */
}

.bn-item.active {
  transform: scale(1.1);
}
.bn-item:not(.active) {
  filter: blur(0.7px); /* Efek blur untuk elemen tidak aktif */
}
.bn-item span {
  margin-top: 1px; /* Tambahkan margin-top untuk jarak dengan ikon */
}

/* TOMBOL HOME */
.bn-item.home:not(.active):hover,
a.home:hover {
  color: #159895 !important;
  filter: blur(0px); /* Efek blur untuk elemen tidak aktif */
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}
/* END TOMBOL HOME */
/* TOMBOL PRESENSI */
.b-navigation .bn-item.presensi.active a {
  color: #e55807 !important;
}
.bn-item.presensi:not(.active):hover,
a.presensi:hover {
  color: #e55807 !important;
  filter: blur(0px); /* Efek blur untuk elemen tidak aktif */
  transition: transform 0.5s ease-in-out;
  transform: scale(1);
}
/* END TOMBOL PRESENSI */
/* TOMBOL ABSENSI */
.b-navigation .bn-item.absensi.active a {
  color: #4c0033 !important;
}
.bn-item.absensi:not(.active):hover,
a.absensi:hover {
  color: #4c0033 !important;
  filter: blur(0px); /* Efek blur untuk elemen tidak aktif */
  transition: transform 0.5s ease-in-out;
  transform: scale(1);
}
/* END TOMBOL ABSENSI */
/* TOMBOL INFO */
.b-navigation .bn-item.info.active a {
  color: #0e2954 !important;
}
.bn-item.info:not(.active):hover,
a.info:hover {
  color: #0e2954 !important;
  filter: blur(0px); /* Efek blur untuk elemen tidak aktif */
  transition: transform 0.5s ease-in-out;
  transform: scale(1);
}
/* END TOMBOL INFO */
/* TOMBOL SALDO */
.b-navigation .bn-item.saldo.active a {
  color: #216353 !important;
}
.bn-item.saldo:not(.active):hover,
a.saldo:hover {
  color: #216353 !important;
  filter: blur(0px); /* Efek blur untuk elemen tidak aktif */
  transition: transform 0.5s ease-in-out;
  transform: scale(1);
}
/* END TOMBOL SALDO */
/* TOMBOL KASBON */
.b-navigation .bn-item.kasbon.active a {
  color: #db005b !important;
}
.bn-item.kasbon:not(.active):hover,
a.kasbon:hover {
  color: #db005b !important;
  filter: blur(0px); /* Efek blur untuk elemen tidak aktif */
  transition: transform 0.5s ease-in-out;
  transform: scale(1);
}
/* END TOMBOL KASBON */
</style>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
  },
  // mounted() {
  //   // Cetak nilai "user" ke konsol saat komponen di-mount (dipasang)
  //   console.log("Data user:", this.user);
  // },
};
</script>